* {
  font-family: Arial;
}

body {
  background-color: white;
  padding: 20px;
}

.ChatRoom {
  border: black dashed 2px;
  padding: 30px;
  padding-bottom: 70px;
  height: calc(80vh - 70px);
  overflow-y: scroll;
  text-wrap: wrap;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.logo {
  height: 7.5vh;
}

.messageForm {
  border: black dashed 1px;
  padding: 10px;
  position: absolute;
  bottom: 30px;
  width: calc(100% - 90px);
}

.messagecontent {
  width: 79%;
  padding: 5px;
  border-radius: 3px;
  border: 1px dotted black;
  margin-left: 0.5%;
}

.username {
  width: 9%;
  padding: 5px;
  border-radius: 3px;
  border: 1px dotted black;
}

.messageForm button {
  width: 9%;
  margin-left: 1%;
  background-color: lightblue;
  border: 1px solid black;
  border-radius: 3px;
  padding: 3px;
  font-size: 15px;
}

.sent {
  color: red;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signout {
  width: 9%;
  margin-left: 1%;
  background-color: lightblue;
  border: 1px solid black;
  border-radius: 3px;
  padding: 3px;
  font-size: 15px;
}